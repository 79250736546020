var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerBooking"},[_c('b-card',_vm._l((_vm.guests),function(guest,index){return _c('div',{key:guest.idForm},[_c('b-card-title',[_c('h5',[_vm._v(_vm._s(_vm.cardTitle({guest: guest, index: index})))]),(guest.mainGuest)?_c('label',[_vm._v("Adulto 1")]):_vm._e(),(!guest.Adult)?_c('label',[_vm._v("Edad: "+_vm._s(guest.Age))]):_vm._e()]),_c('b-row',[(guest.mainGuest)?_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Prefijo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Prefijo"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.guests)}},model:{value:(guest.NamePrefix),callback:function ($$v) {_vm.$set(guest, "NamePrefix", $$v)},expression:"guest.NamePrefix"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.namesPrefix),function(namesF,index){return _c('option',{key:index,domProps:{"value":namesF.value}},[_vm._v(" "+_vm._s(namesF.text)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.guests)}},model:{value:(guest.GivenName),callback:function ($$v) {_vm.$set(guest, "GivenName", $$v)},expression:"guest.GivenName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required","name":"Apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Apellido"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.guests)}},model:{value:(guest.Surname),callback:function ($$v) {_vm.$set(guest, "Surname", $$v)},expression:"guest.Surname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1),(guest.Adult && guest.mainGuest)?_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required","name":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('b-form-input',{attrs:{"type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"10","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.guests)}},model:{value:(guest.PhoneNumber),callback:function ($$v) {_vm.$set(guest, "PhoneNumber", $$v)},expression:"guest.PhoneNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.guests)}},model:{value:(guest.Email),callback:function ($$v) {_vm.$set(guest, "Email", $$v)},expression:"guest.Email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1):_vm._e(),(((index+1) < _vm.guests.length))?_c('hr'):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }