<template>
  <ValidationObserver ref="observerBooking">
    <b-card>
      <div v-for="(guest, index) in guests" :key="guest.idForm">
        <b-card-title>
          <h5>{{cardTitle({guest, index})}}</h5>
          <label v-if="guest.mainGuest">Adulto 1</label>
          <label v-if="!guest.Adult">Edad: {{guest.Age}}</label>
        </b-card-title>
          <b-row>
            <b-col v-if="guest.mainGuest">
              <ValidationProvider name="Prefijo" rules="required">
                <b-form-group label="Prefijo" slot-scope="{ valid, errors }">
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="guest.NamePrefix"
                    @input="setDataInBooking(guests)"
                  >
                    <option value="">Seleccione una opción</option>
                    <option
                      v-for="(namesF, index) in namesPrefix"
                      :key="index"
                      :value="namesF.value"
                    >
                      {{ namesF.text }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback
                    >{{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider rules="required" name="Nombre">
                <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="text"
                    v-model="guest.GivenName"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="setDataInBooking(guests)"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider rules="required" name="Apellido">
                <b-form-group label="Apellido" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="text"
                    v-model="guest.Surname"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="setDataInBooking(guests)"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row v-if="guest.Adult && guest.mainGuest">
            <b-col>
              <ValidationProvider rules="required" name="Teléfono">
                <b-form-group
                  label="Teléfono"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-input
                    type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    v-model="guest.PhoneNumber"
                    maxlength="10"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="setDataInBooking(guests)"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider rules="required|email" name="Email">
                <b-form-group label="Email" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="email"
                    v-model="guest.Email"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="setDataInBooking(guests)"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <hr v-if="((index+1) < guests.length)">
      </div>
    </b-card>
  </ValidationObserver>
</template>

<script>
import { namesPrefix } from '@/data/data'

export default {
  props: {
    guests: {
      type: Array,
      required: true,
    },
  },
  data(){
    return {
      namesPrefix,
      childages: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
    }
  },
  methods: {
    setDataInBooking(data){
      this.$emit('set-guests-in-properties', data)
    },
    cardTitle(data){
      const {guest, index} = data
      if (guest.mainGuest) return `Main Guest: ${guest.GivenName} ${guest.Surname}`
      else return guest.Adult ? `Adulto ${index+1}: ${guest.GivenName} ${guest.Surname}` : `Niño ${index+1}: ${guest.GivenName} ${guest.Surname}`
    },
    rulesRequired(data){
      const {index, adult} = data
      return adult && index == 0 ? 'required' : ''
    }
  }
}
</script>